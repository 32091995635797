header.home-header
{
    background: linear-gradient(90deg, black, rgba(0, 0, 0, .8));
    backdrop-filter: blur(4px);
    display: flex;

    align-items: center;
    min-height: 65px;
    padding: .5rem 1.5rem .5rem 1rem;
    position: sticky;
    top: 0;
    z-index: 10000;
    /* top: 20px; */
    margin: 1.5rem auto;
    width: 95%; 
    border-radius: 50px;
    cursor: pointer;
    transform-origin: bottom;
    /* filter: blur(1px); */
    transition: all 350ms ease-in-out;
}

@media only screen and (min-width: 1200px) 
{
    header.home-header:hover 
    {
        width: 100%;
        border-radius: 0;
        margin-top: -2rem;
        margin-bottom: 2rem;
        /* height: 100px; */
        /* margin-top: 0; */
        /* filter: blur(0px); */
    }
}



header.home-header .list 
{
    display: flex;
    align-items: center;
    width: 100%;
    height: 100%;

}

header.home-header .navigation-icon 
{
    display: none;
}



header.home-header .logo
{
    display: flex;
    margin: 0 1rem;
    align-items: center;

}

header.home-header .logo p
{
    font-weight: bolder;
    color: var(--home-opposite-text-color);
    display: none;
}

header.home-header .items
{
    display: flex;
    justify-content: flex-start;
 

}

header.home-header .items .item 
{
    color: var(--home-opposite-text-color);
    cursor: pointer;
    font-size: 16gpx;
    font-family: "Inter";
    font-weight: 600;
    margin-left: 16px;
    margin-right: 16px;
}

header.home-header .items .item a 
{
    text-decoration: none;
    color: inherit;
}

header.home-header .items .item.active a
{
    border-bottom: 3px solid var(--home-contrast-color);
    
}



header.home-header .right-container,
header.home-header .left-container
{
    display: flex;
    flex: 1;
    width: 100%;
    
}

header.home-header .right-container
{
    justify-content: flex-end;
    align-items: center;
    margin-left: auto;
}

header.home-header .right-container .button
{
    width: 100%;
    min-width: 100px;
    width: auto;
    padding: 1rem 1rem;
    max-height: 35px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 2px solid var(--home-contrast-color);
    border-radius: 35px;
    cursor: pointer;
    transition: all 400ms ease-in-out;
    
}

header.home-header .right-container .button p 
{
    color: var(--home-opposite-text-color);
    font-family: "Inter";
    font-weight: 700;
    font-size: 18px;
    transition: all 400ms ease-in-out;
}

header.home-header .right-container .button:hover 
{
    background: var(--home-contrast-color);
}

header.home-header .right-container .button:hover p
{
    color: var(--home-opposite-text-color);
}

@media only screen and (max-width: 900px)
{

    header.home-header 
    {
        position: fixed;
        width: calc(100% - 2rem);
        margin-left: 1rem;
        /* margin-top: 0; */
        display: none;
    }

    header.home-header .list
    {
        display: none;
        position: absolute;
        /* left: -200%; */
        transition: all 500ms ease-in-out;
    }

    header.home-header .active-navigation 
    {
        background: var(--home-opposite-background-color);
    }

    header.home-header .left-container, header .right-container
    {
        display: block;
    }

    header.home-header.active-navigation .list
    {
        display: flex;
        flex-direction: column;
        left: 0;
        width: 100%;
        background: #000;
        height: 100%;
        top: 70px;
        height: max-content;
        left: 0;
        align-items: center;
        backdrop-filter: blur(10px);
        z-index: 10000;
        border-radius: 20px;
        justify-content: center;
        padding-top: 2rem;
    }

    header.home-header .right-container .button
    {
        width: 90%;
    }
    
    header.home-header.active-navigation .list .left-container .items
    {
        flex-direction: column;
        gap: 3rem;
    }

    header.home-header.active-navigation .list .logo
    {
        display: none;
    }
   

    header.home-header .navigation-icon 
    {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-left: auto;
        cursor: pointer;
    }

    header.home-header .navigation-icon .line 
    {
        width: 35px;
        height: 3px;
        background-color: var(--home-opposite-text-color);
        margin-top: .3rem;
        margin-bottom: .3rem;
        transition: all 350ms ease-in-out;
    }

    header.home-header.active-navigation .navigation-icon .line:first-child
    {
        transform: rotateZ(35deg) translateY(15px) skewY(10deg);
    }

    header.home-header.active-navigation .navigation-icon .line:last-child 
    {
        transform: rotateZ(-35deg) translateY(-15px) skewY(-10deg);
    }

    header.home-header.active-navigation .navigation-icon .line:nth-child(2) 
    {
        opacity: 0;
    }

    header.home-header .items .item
    {
        font-size: 20px;
        text-align: center;
       
    }

    header.home-header .right-container, header .left-container
    {
        width: 90%;
        /* margin-left: auto;
        margin-right: auto; */
    }

    header.home-header .right-container
    {
        margin-left: 0;
        margin-block: 2rem;
    }

    header.home-header .right-container,
    header.home-header .left-container
    {
        justify-content: center;

        
    }


  
}