.signs-container 
{
    width: 100%;
    min-height: 100vh;
}

.signs-container h2 
{
    color: rgb(89, 123, 226);
    text-align: center;
    font-size: 40px;
}

.pages-container  .signs-container .elements 
{
    width: 90%;
    margin: 3rem auto;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    justify-content: center; 
    align-items: center;
    grid-gap: 2rem;
    
}

.pages-container .element 
{
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    border: 2px solid rgb(89, 123, 226);
    padding: .5rem;
    border-radius: 20px;
    height: 100%;
    cursor: pointer;
    transition: all 250ms ease-in-out;
}

.pages-container .element p 
{
    text-align: center;
}

.pages-container .element:hover 
{
    box-shadow: 0 0 10px rgb(89, 123, 226);
}

.pages-container .element img 
{
    width: 100%;
    height: 20vh;
}

.signs-container .go-back 
{
    position: absolute;
    left: 15px;
    top: 15px;
    border: 2px solid rgb(89, 123, 226);
    border-radius: 50%;
    padding: 1rem;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 350ms ease-in-out;
}

.signs-container .go-back .icon 
{
    width: 30px;
    height: 30px;
    transition: all 350ms ease-in-out;
}

.signs-container .go-back:hover 
{
    background: rgb(89, 123, 226);
    
}

.signs-container .go-back:hover .icon 
{
    color: #fff;
}