.start-button-container 
{
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 350ms ease-in-out;
}

.start-button-container.active
{
    z-index: 1000000000000;
    background: rgba(0, 0, 0, .5);
    backdrop-filter: blur(6px);
    cursor: pointer;
}

.start-button-container:not(.active) button 
{
    display: none;
}

.start-button-container button 
{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 30%;
    padding: 2rem 0;
    border-radius: 1rem;
    border: none;
    outline: none;
    font-size: 32px;
    font-weight: bold;
    background: linear-gradient(135deg, #4481eb 0%, #04befe 100%);
    color: #fff;
    font-family: "Source Sans Pro";
    cursor: pointer;
    border: 2px solid rgba(0, 0, 0, 0);
    transition: all 350ms ease-in-out;
}   

.start-button-container button:hover
{
    border: 2px solid #fff;
}

.microphone-container 
{
    position: fixed;
    bottom: 15px;
    left: calc(50%);
    transform: translateX(-50%);
    z-index: 100000000000;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1.5rem;
    border-radius: 100px;
    padding: 1.25rem 1.5rem;
    background: linear-gradient(135deg, #000 50%, #a6a2a2);
    cursor: pointer;
    color: #fff;
    transition: all 350ms ease;
}

.microphone-container.not-show 
{
    gap: 1rem;
}

.microphone-container p
{
    font-size: 18px;
    transition: all 350ms ease-in-out;
}

.microphone-container .recording-dot
{
    background: crimson;
    width: 15px;
    height: 15px;
    border-radius: 50%;
    transition: all 350ms ease-in-out;
    margin-left: -16px;
    animation: pulse 1.5s infinite linear;
}

.microphone-container.recording-audio 
{
}


@keyframes pulse 
{
    0% {
        opacity: 1;
    }

    50% {
        opacity: 0.35;
    }

    100% {
        opacity: 1;
    }
}


.microphone-container.not-show p
{
    font-size: 0px;
    opacity: 0;
}

.microphone-container:hover 
{
    padding: 1rem 2.5rem;
    border-radius: 36px;
    gap: 1.5rem;
    
}

.microphone-container:hover p
{
    display: block;
    font-size: 18px;
    opacity: 1;
}

.microphone-container .microphone-icon 
{
    font-size: 24px;
    margin-right: -14px;
}



.intreruptor,
.separator,
.generator
{
    transition: all 350ms ease-in-out;
}


.active-select
{
    transform: scale(1.5);
}


/* Manoeuver File */

.manoeuver-big-file 
{
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(0, 0, 0, .5);
    backdrop-filter: blur(6px);
    opacity: 0;
    z-index: -2;
    transition: all 350ms ease-in-out;
}

.manoeuver-big-file.active 
{
    opacity: 1;
    z-index: 1000;
}

/* Book */
.manoeuver-file {
    position: fixed;
    z-index: 10000;
    aspect-ratio: calc(1 / 1.41);
    height: 90vh;
    align-self: center;
    top: 20px;
    backdrop-filter: blur(6px);
    transition: transform 0.5s;
}

.paper-manoeuver-file {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    perspective: 1500px;

}

.front,
.back {
    background-color: white;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    border-radius: 8px;
    transform-origin: left;
    box-shadow: 0 0 12px #000;
    transition: transform 1s;
}

.front {
    z-index: 1;
    backface-visibility: hidden;
    border-left: 3px solid powderblue;
}


.front .first-row
{
    margin-top: 16px;
    margin-inline: 12px;
    display: flex;
    justify-content: space-between;
}

.front .first-row p
{
    font-size: .85vw;
    margin-block: 12px;
}

.front .first-row .col-2 p
{
    font-size: .75vw;
}

.back {
    z-index: 0;
    
}

.front-content,
.back-content {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
}

.back-content {
    margin-top: 1vw;
    width: 90%;
    margin-inline: auto;
    transform: rotateY(180deg);
}

/* Paper flip effect */
.flipped .front,
.flipped .back {
    transform: rotateY(-180deg);
}

/* Controller Buttons */
.button-manoeuver-file {
    border: none;
    background-color: transparent;
    cursor: pointer;
    margin: 10px;
    transition: transform 1s;
}

.button-manoeuver-file:focus {
    outline: none;
}

.button-manoeuver-file:hover i {
    color: #636363;
}

.prev-button,
.next-button
{
    position: fixed;
    z-index: 1000;
    transition: all 350ms ease-in-out;
}

.prev-button.hide,
.next-button.hide
{
    pointer-events: none;
    opacity: 0;
}

.prev-button
{
    left: 25%;
}

.next-button 
{
    right: 25%;
}

.arrow-icon {
    font-size: 3vw;
    padding: .5rem .75rem;
    color: #fff;
    cursor: pointer;
    z-index: 100000;
    position: relative;
    transition: all 350ms ease-in-out;
}

.arrow-icon:hover 
{
    background: #fff;
    border-radius: 50%;
    color: #000;
}

/* Paper stack order */
.page-1 {
    z-index: 3;
}

.page-2 {
    z-index: 2;
}


.file-title 
{
    text-align: center;
    font-size: 1.05vw;
}

.row-manoeuver-file 
{
    margin: 12px 30px;
    display: flex;
    flex-direction: column;
    gap: 6px;
}

.row-manoeuver-file p 
{
    font-size: .85vw;
}

.flex-row
{
    margin: .7vw -2vw 0 -2.5vw;
    display: flex;
    /* gap: 30px; */
    justify-content: space-around;
}

.flex-row p 
{
    margin-top: 6px;
    margin-bottom: 6px;
    font-size: .8vw;
    
}

.table .layout-1
{
    display: grid;
    grid-template-columns: 6.875vw 29.05vw;
    
}

.table .layout-2 {
    display: grid;
    justify-content: center;
    grid-template-columns: 3.55vw 3.55vw 3vw 26vw;
    grid-template-rows: 1fr;
    grid-auto-rows: 1fr;
}


.table .layout-1 .content-col
{
    border: .2vw solid #000;
    border-left: none;
    
}

.table .layout-1 .content-col:last-child,
.table .layout-2 .content-col:last-child
{
    border-right: none;
}

.table .layout-1 .content-col p,
.table .layout-2 .content-col p
{
    text-align: center;
    font-size: .7vw;
}


.table .layout-2 .content-col
{
    border: .2vw solid #000;
    border-left: none;
    border-top: none;
    height: 100%;
    padding: .25vw;
}



.table .layout-2 .content-col
{
    display: flex;
    align-items: center;
}

.table .layout-2.layout-center .content-col
{
    justify-content: center;
}

.table .layout-2 .content-col:last-child p
{
    text-align: initial;
}

.table .layout-2 .content-col:nth-child(3)
{
    justify-content: center;
}

.back-content .layout-2.layout-center
{
    border-top: .35vw solid #000;
}

.page-2 .layout-1
{
    margin-top: 1vw;
    border-top: .2vw solid #000;

}

.print-button 
{
    position: fixed;
    bottom: 10px;
    left: calc(50%);
    transform: translateX(-50%);
    z-index: 100000000000;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    border-radius: 10px;
    padding: .65rem 2rem;
    background: linear-gradient(135deg, #256de9, #04befe);
    cursor: pointer;
    color: #fff;
    transition: all 350ms ease-in-out;
}

.print-button:hover 
{
    transform: translateX(-50%) scale(1.15);
    /* border-radius: 100px; */
}

.print-button .print-icon 
{
    font-size: 20px;
    color: #fff;
}

.print-button p 
{
    font-size: 20px;
    color: #fff;
    
}

.accept-container 
{
    display: flex;
    align-items: center;
    margin-left: .5vw;
    margin-top: 1.5vw;
    gap: .35vw;
}

.accept-container p
{
    font-size: 1.15vw;
}

.accept-container .box 
{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 1.5vw;
    height: 1.5vw;
    border: 3px solid #000;
    cursor: pointer;
    transition: all 350ms ease-in-out;
}

.accept-container .box:hover 
{
    border-radius: 4px;
    width: 1.65vw;
    height: 1.65vw;
}

.accept-container .box.active
{
    border-color: rgb(90, 218, 90);
}

.accept-container .box .check-icon 
{
    font-size: 1.2vw;
    
}

.microphone-container.close-container 
{
    bottom: initial;
    top: 15px;
    right: 15px;
    left: initial;    
    transform: initial;
    padding: 1rem 1.5rem;
}

.microphone-container.close-container:hover 
{
}

.flipped .front.flip-y 
{
    transform: rotateY(0deg);
}

.back.back-flip-y
{
    transform: rotateY(-180deg);
}

.microphone-container.left-container 
{
    left: 20px;
    transform: none;
    right: initial;
    background: linear-gradient(135deg, crimson, #000);
    
}

.clock-container 
{
    position: fixed;
    z-index: 2;
    top: 15px;
    left: 50%;
    transform: translateX(-50%);
    background: linear-gradient(135deg, #000 60%, #a6a2a2);
    padding: .5rem 1.5rem;
    border-radius: 50px;
}

.clock-container h2 
{
    font-size: 25px;
    color: #fff;
    
}