.leaderboard .row > div, 
.leaderboard .user > .column
{
    display: flex;
    justify-content: center;
    align-items: center;
}

.leaderboard .user > .column .column-icon
{
    width: 24px;
    height: 24px;
    border-radius: 50%;
    /* border: 2px solid #4481eb; */
    color: #000;
    cursor: pointer;
    transition: all 350ms ease-in-out;
}

.leaderboard .user > .column .column-icon:hover 
{
    width: 28px;
    height: 28px;
}

.leaderboard .user > .column .column-icon.active
{
    border: 3px solid #000;
    color: #4481eb;
}

.leaderboard .user > .column .column-container 
{
    
}

.results-container 
{
    width: 90%;
    margin: 2rem auto;
}

.results-container li,
.results-container > h2
{
    margin: .5rem 0;
}

.results-container li
{
    margin-left: 1rem;
    font-size: 18px;
}


.user-results 
{
    display: flex;
    /* justify-content: center; */
    align-items: stretch;
    gap: 2rem;
    margin-bottom: 5rem;
}

.user-results .column 
{
    flex: 1;
    /* display: flex;
    flex-direction: column;
    /* justify-content: center; */
    /* border: 2px solid #000; */
    /* align-items: center; */
    /* background: #D2D2D2; */
     border: 5px solid #4481eb;
    border-radius: 16px;
    /* align-items: stretch; */
    /* height: 1fr; */
    /* height: fit-content; */
}

.user-results .column:first-child
{
    padding: 2rem 1rem;
}

.user-results .column:nth-child(2)
{
    padding: 1rem;
}


.user-results .column > h2 
{
    text-align: center;
    margin: 1rem auto;
}

.user-results .column p,
.user-results .column .item-icon
{
    font-size: 20px;
}

.user-results .column:first-child .item 
{
    margin-block: 1rem;
}

.user-results .column:last-child .item 
{
    margin-block: .5rem;
}

.user-results .column .item-icon
{
    color: #4481eb;
    font-size: 24px;
}

.user-results .column .item-icon.warning 
{
    color: orange;
}

.user-results .column .item-icon.wrong
{
    color: crimson;
}

.user-results .column .item-container
{
    width: 100%;
    padding: 1rem;
    border: 5px solid #04befe;
    border-radius: 16px;
    margin: 1rem auto;
}

.user-results .column .item-container:nth-child(2n + 1)
{
    background: rgba(125, 125, 125, .1);
}

.user-results .column .item 
{
    display: flex;
    /* justify-content: center; */
    align-items: center;
    gap: 1rem;
}

h2.sub-title 
{
    text-align: center;
    margin: 2rem auto;
    margin-right: -1rem;
}

h2.sub-title span 
{
    background: linear-gradient(135deg, #4481eb 0%, #04befe 100%);
    margin-left: 1rem;
    padding: .5rem 1rem;
    border-radius: 8px;
    color: rgba(255, 255, 255, .9);
    cursor: pointer;
    transition: all 500ms ease-in-out;
}

h2.sub-title span:hover
{
    color: #fff;
    border-radius: 50px;
}