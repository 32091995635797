#root 
{
    /* align-items: center; */
    width: 100%;
    min-height: 100vh;
    display: flex;
    
}

body 
{
    transition: all 350ms ease-out;
}

.home-container
{
    width: 100%;
    min-height: 100vh;
    background: var(--light-color);
}

.home-container .content 
{
    width: 100%;
    margin-left: initial;
    margin-inline: auto;
    max-width: 100%;
    padding: 0;
    
}

.home-container .content h2.title
{
    font-size: 80px;
    font-weight: 800;
    color: var(--home-text-color);
    margin-bottom: 0;
    margin-top: 5vh;
    font-family: "Inter";
    text-align: center;
}

.home-container .content h2.title.sm-title 
{
    font-size: 60px;
}

.home-container .content h2.title span
{
    background: -webkit-linear-gradient(0deg, var(--home-contrast-color-2), var(--home-contrast-color));
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
   
}

.home-container .content h2.title span.swoosh
{
    /* display: flex;
    justify-content: center;
    flex-direction: column; */
    position: relative;
}

.home-container .content h2.title span img 
{
    width: 100%;
    display: block;
    /* margin: 0 auto; */
    position: absolute;
    left: 0px;
    bottom: -30px;
    transform: rotateY(180deg);
}


.home-container .content h2.title.last span
{
    background: -webkit-linear-gradient(0deg, var(--home-contrast-color), var(--home-contrast-color-2));
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.home-container .content p.description 
{
    font-size: 22px;
    line-height: 29px;
    font-weight: 500;
    max-width: 60%;
    font-family: "Inter";
    color: var(--home-text-color);
    text-align: center;
    margin-inline: auto;
    margin-top: 3rem;
}

.home-container .content .final-block
{
    background: var(--home-opposite-background-color);
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-height: 55vh;
    position: relative;
}

.home-container .content .final-block h2
{
    color: var(--home-opposite-text-color);
    font-size: 80px;
    margin-top: 0;
    
}

.home-container .content .final-block p 
{
    color: var(--home-opposite-text-color);
    position: relative;
    bottom: 10px;
    font-size: 20px;
    margin-block: 1rem 0;
}

.home-container .content .final-block > p:first-child
{
}

.home-container .content .block-1
{
    display: flex;
    flex-direction: column;
    /* min-height: 100vh; */
}

.home-container .content .block-1 button,
.home-container .content .block-5 button 
{
    margin: 2.5rem auto 15vh;
    background: linear-gradient(135deg, var(--home-contrast-color), var(--home-contrast-color-2));
    border: none;
    outline: none;
    color: var(--home-opposite-text-color);
    padding: 1.5rem 2.5rem;
    border-radius: 36px;
    cursor: pointer;
    font-family: "Inter";
    font-weight: 600;
    font-size: 16px;
    position: relative;
    z-index: 100;
    
    transition: all 350ms ease-in-out;
}

.home-container .content .block-1 button p,
.home-container .content .block-5 button p
{
    display: flex;
    gap: .5rem;
    justify-content: center;
    align-items: center;
}

.home-container .content .block-5 button 
{
    margin-bottom: 0vh;
}

.home-container .content .block-1 button svg,
.home-container .content .block-5 button svg 
{
    font-size: 18px;
}

.home-container .content .block-1 button:hover,
.home-container .content .block-5 button:hover
{
    padding: 1.5rem 3.5rem;
    box-shadow: 0 0 10px var(--home-contrast-color);
}

.home-container .content .block-2 
{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: -5vw;
}

.home-container .content .final-block{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1.5rem;
    /* padding-block: 1rem 0; */

}

.home-container .content .final-block img 
{
    width: 100%;
    max-width: 75px;
}

.home-container .content img.mockup 
{
    width: 80vw;

}

.home-container .content .advantages 
{
    display: grid;
    grid-template-columns: repeat(2, minmax(350px, 1fr));
    grid-template-rows: 1fr 1fr;
    grid-gap: 2rem;
    max-width: 85%;
    margin: 5rem auto;
}

.home-container .content .advantages .advantage 
{
    background-image: linear-gradient(var(--home-background-container), var(--home-background-container));
    padding: 1rem 1rem 3rem 1rem;
    border-radius: 1.5rem;

    position:relative;
    cursor: pointer;
    transition: all 350ms ease-in-out;
    z-index: 1;
}


.home-container .content .advantages .advantage span 
{
    font-size: 35px;
}

/* .home-container .content .advantages .advantage:hover 
{
    opacity: 0;

} */

.home-container .content .advantages .advantage:hover h2,
.home-container .content .advantages .advantage:hover p
{
    color: var(--home-opposite-text-color);
}

.home-container .content .advantages .advantage::before 
{
    position: absolute;
    content: '';
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-image: linear-gradient(135deg, var(--home-contrast-color), var(--home-contrast-color-2));
    z-index: -1;
    border-radius: 32px;
    transition: opacity 0.35s ease-in-out;
    opacity: 0;
}
  
.home-container .content .advantages .advantage:hover::before 
{
    opacity: 1;
    /* z-index: 1; */
}

.home-container .content .advantages.videos-container .advantage
{
    padding: 0 0rem 0 0rem;
}

.home-container .content .advantages.videos-container .advantage h2
{
    text-align: center;
}

.home-container .content .advantages .advantage .video-container 
{
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 1.5rem;
    margin-bottom: -6rem;
    transition: all 350ms ease-in-out;
}

.home-container .content .advantages .advantage .video-container:hover 
{
    filter: brightness(1.25);
}

.home-container .content .advantages .advantage .video-container .play-button,
.home-container .content .advantages .advantage .video-container .fullscreen-button
{
    user-select: none;
    opacity: 0;
    position: absolute;
    z-index: 10;
    background: linear-gradient(135deg, var(--home-contrast-color), var(--home-contrast-color-2));
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 350ms ease-in-out;
}

.home-container .content .advantages .advantage .video-container .fullscreen-button
{
    background: rgba(255, 255, 255, .2);
}

.home-container .content .advantages .advantage .video-container .fullscreen-button
{
    right: 15px;
    bottom: 15px;
}

.home-container .content .advantages .advantage .video-container:hover .play-button,
.home-container .content .advantages .advantage .video-container:hover .fullscreen-button
{
    cursor: pointer;
    opacity: 1;
    user-select: initial;
}

.home-container .content .advantages .advantage .video-container .play-button .play-icon
{
    color: var(--home-opposite-text-color);
    width: 35px;
    height: 35px;
    padding: 1rem;
}

.home-container .content .advantages .advantage .video-container .fullscreen-button .fullscreen-icon
{
    width: 25px;
    height: 25px;
    padding: .5rem;
    color: var(--home-opposite-text-color);

}

.home-container .content .advantages .advantage p 
{
    font-size: 20px;
    font-weight: 600;
    margin-top: 1rem;
}

.home-container .content .advantages .advantage p.enumerate 
{
    display: list-item;
    margin: 1rem;
}

.home-container .content .advantages .advantage .advantage-icon 
{
    width: 115px;
  
    padding: 1rem;
    position: absolute;
    bottom: -5px;
    right: 5px;
    border-radius: 50%;
    cursor: pointer;
    color: var(--home-contrast-color);
    transition: all 350ms ease-in-out;


}

.home-container .content .advantages .advantage:first-child .advantage-icon
{
    transform: scale(0.9);
    right: -5px;
    bottom: -10px;
}

.home-container .content .advantages .advantage:nth-child(3) .advantage-icon
{
    transform: scale(1.5);
    bottom: 5px;
    right: 5px;
 
}

.home-container .content .advantages .advantage:nth-child(2)
{
    padding-bottom: 6rem;
}

.home-container .content .advantages .advantage:nth-child(2) .advantage-icon
{
    transform: scale(1.2);
}

.home-container .content .advantages .advantage .advantage-icon:hover
{
 
}

.home-container .content .advantages .advantage h2 
{
    font-size: 30px;
    line-height: 36px;
}

.home-container .content .block-6,
.home-container .content .block-4
{
    width: 100%;
    max-width: 85%;
    background: linear-gradient(-90deg, var(--home-contrast-color), var(--home-contrast-color-2));
    display: flex;
    margin: 15rem auto;
    align-items: center;
    
    min-height: 50vh;
    border-radius: 25px;

}

.home-container .content .block-4
{
    margin-block: 5rem;

}

.home-container .content .block-4 .block-container 
{
    flex: 0.8;
    margin-inline: 1.5rem;
    display: flex;
    flex-direction: column;
   
}

.home-container .content .block-4 .block-container:nth-child(2)
{
    flex: 1;
    align-items: flex-end;
    margin-inline: 0;
  
    border-top-right-radius: 25px;
    border-bottom-right-radius: 25px;
}

.home-container .content .block-4 .block-container img 
{
    width: 100%;
    border-bottom-right-radius: 25px;
    border-top-right-radius: 25px;
    align-self: flex-end;
    max-width: 100%;
    transform: scale(1.2);
    /* box-shadow: 0 0 20px #fff; */
    
}

.home-container .content .block-4 .shadow-line 
{
    /* flex: 0.05; */
    box-shadow: 0 0 20px #fff;
    width: 5px;
    height: 50vh;
    background: #fff;
    transform: rotateZ(20deg) scaleY(1.2) translateX(10px);
}

.home-container .content .block-4 .block-container h2 
{
    font-size: 50px;
    color: var(--home-opposite-text-color);
    margin-bottom: 2rem;
}


.home-container .content .block-4 .block-container p 
{
    color: var(--home-text-color-transparent);
    font-size: 20px;
    font-weight: 600;
}


.block-modules
{
    min-height: 90vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    
}

.modules-container
{
    display: flex;
    width: 90%;
    margin: 5vh auto 10vh;
    gap: 3rem;
    
}

.modules-container .modules 
{
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex: 1;
    gap: 2rem;
}

.modules-container .modules h2
{
    margin: 0 auto;
    display: flex;
    align-items: center;
}

.modules-container .modules h2 span
{
    font-size: 32px;
    margin-left: 8px;
}

.modules-container .modules:first-child
{
    align-items: flex-start;
}

.modules-container .modules:last-child
{
    align-items: flex-start;
}

.modules-container .modules .module
{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    width: 100%;
    background-image: linear-gradient(135deg, #000 35%, #63808a);
    padding: 1rem 1.5rem;
    border-radius: 32px;
    cursor: pointer;
    position: relative;
    z-index: 1;
    transition: all 350ms ease-in-out;
}

.modules-container .modules .module::before {
    position: absolute;
    content: '';
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-image: linear-gradient(-90deg, var(--home-contrast-color), var(--home-contrast-color-2));
    z-index: -1;
    border-radius: 32px;
    transition: opacity 0.35s ease-in-out;
    opacity: 0;
}
  
.modules-container .modules .module.active::before {
    opacity: 1;
}

.modules-container .modules .module.active::before
{
    border-radius: 32px;
}

.modules-container .modules .module.active:hover::before
{
    border-radius: 32px;
}

.modules-container .modules .module.active
{
    border-radius: 32px;
    transform: scale(1.075);
}


.modules-container .modules .module:hover
{
    border-radius: 32px;
}

.modules-container .modules .module p 
{
    color: #fff;
    font-size: 24px;
    font-weight: 600;

}

.modules-container .modules .module.active p 
{
    font-weight: bold;
}

.modules-container .modules:first-child .module 
{
    justify-content: center;
    padding-left: 2rem;
}

.modules-container .modules:last-child .module 
{
    justify-content: center;
    padding-right: 2rem;
}

.modules-container .modules .module .module-icon
{
    color: #fff;
}


.modules-container .module-context 
{
    display: flex;
    flex-direction: column;
    align-items: center;
    /* justify-content: center; */
    flex: 2;
    position: relative;
    background-image: linear-gradient(135deg, #000 35%, #63808a);
    /* width: 70%; */
    border-radius: 12px;
    padding: 1rem;
    /* min-height: 40vh; */
}

.modules-container .module-context img
{
    position: absolute;
    width: 50px;
    bottom: 5px;
    left: 5px;
}

.modules-container .module-context h2 
{
    color: #fff;
    font-size: 32px;
  
}

.modules-container .module-context p
{
    margin: 1.5rem auto 3.5rem;
    color: #d0cccc;
    font-size: 18px;
}

.modules-container .module-context ul 
{
    margin: 1rem;
}


.block-modules
{

}

.home-container .content .block-6 .block-container img 
{
    width: 100%;
    max-width: 345px;
    border-inline: 5px solid #fff;;
}   

.home-container .content .block-6 .block-container h2 
{
    font-size: 60px;
    text-align: center;
    color: var(--home-opposite-text-color)
}


.home-container .content .block-6 .block-container 
{
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    padding-inline: 1rem;
}

.home-container .content .block-6 .block-container:nth-child(2)
{
    padding-inline: 0;
    max-width: 345px;
}

.home-container .content .block-6 .block-container:last-child
{
    display: inline;
}

.home-container .content .block-6 .block-container p 
{
    display: inline;
 
}

.home-container .content .block-6 .block-container p
{
    color: var(--home-text-color-transparent);
    font-size: 20px;
}

.home-container .content .block-6 .block-container p.highlight
{
    color: var(--home-opposite-text-color);
    font-weight: 700;
}



.home-container .content .block-6 .block-container p:nth-child(n + 2)
{
    margin-inline: .25rem;
}

.home-container .content .fixed-icon-container 
{
    position: fixed;
    bottom: 15px;
    right: 15px;
    background: linear-gradient(90deg, rgba(0, 0, 0, .9), rgba(0, 0, 0, .7));
    backdrop-filter: blur(10px);
    width: 75px;
    height: 75px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    border: 2px solid var(--home-opposite-text-color);
    transition: all 350ms ease-in-out;
}

.home-container .content .fixed-icon-container:hover 
{
    box-shadow: 0 0 10px rgba(0, 0, 0, .8);
}

.home-container .content .fixed-icon-container .fixed-icon 
{
    font-size: 45px;
    color: #25D366;
}

@media only screen and (max-width: 1200px)
{
    
    .home-container .content .final-block h2
    {
        font-size: 60px;
    }
}

@media only screen and (max-width: 1200px)
{
    .modules-container
    {
        flex-direction: column;
    }

}

@media only screen and (max-width: 1100px)
{
    .block-4 
    {
        flex-direction: column;
 
    }

    .home-container .content .block-6 .block-container:first-child
    {
        position: relative;
        margin-top: -200px;
    }

    .home-container .content .block-6 .block-container img
    {
        border-inline: none;
    }

    .home-container .content .block-6
    {
        flex-direction: column;
        padding-bottom: 5rem;
        background: linear-gradient(180deg, var(--home-contrast-color) 60%, var(--home-contrast-color-2));
        margin: 15rem auto 5rem auto;
    }

    .home-container .content .block-6 .block-container:last-child
    {
        width: 90%;
    }

    .home-container .content .block-6 .block-container p 
    {
        font-size: 24px;
    }

    .block-4 .block-container h2 
    {
        text-align: center;
    }

    .home-container .content .block-6, .home-container .content .block-4
    {
        max-height: max-content;
    }

    .home-container .content .block-4 .block-container p
    {
        max-width: 90%;
        margin: 1rem auto;
        font-size: 23px;
    }

    .home-container .content .block-4
    {
        background: linear-gradient(0deg, var(--home-contrast-color) 30%, var(--home-contrast-color-2))
    }

    .home-container .content .block-4 .shadow-line
    {
        transform: rotateZ(75deg) scaleY(5);
        height: 25vh;
    }

    .home-container .content .block-4 .block-container img
    {
        max-width: 90%;
        margin: 0 auto;
    }

    .home-container .content .block-4 .block-container h2
    {
        max-width: 70%;
        margin: 2rem auto;
        font-size: 50px;
    }

    .home-container .content .final-block h2
    {
        font-size: min(5vw, 50px);
        width: 80%;
    }

    .home-container .content .block-6 .block-container:nth-child(2)
    {
        max-width: 100%;
    }

  
  
}

@media only screen and (max-width: 1000px)
{
    .home-container .content h2.title
    {
        font-size: 7.5vw;
    }

    /* .home-container .content .final-block img
    {
        display: none;
    } */

    
}

@media only screen and (max-width: 900px)
{
    .home-container .content .advantages
    {
        display: flex;
        flex-direction: column;
    }

    .home-container .content .advantages .advantage h2
    {
        text-align: center;
    }

    .home-container .content h2.title
    {
        margin-top: 15vh;
    }
}

@media only screen and (max-width: 800px)
{
    .home-container .content h2.title:not(.last)
    {
        font-size: max(10vw, 40px);
    }
    
    .home-container .content .block-1 button,
    .home-container .content .block-5 button
    {
        margin-bottom: 0vh;
    }
  
    .home-container 
    {
        overflow-x: hidden;
    }
}



@media only screen and (max-width: 600px)
{
    .home-container .content .final-block h2
    {
        font-size: 40px;
        width: 90%;
    }

    .home-container .content .advantages
    {
        max-width: 90%;
        margin: 3rem auto;
    
    }

    .home-container .content .block-4 .block-container p
    {
        font-size: min(23px, 10vw);
        max-width: 100%;
    }

    .home-container .content .block-4 .block-container img
    {
        max-width: 110%;
    }

    .home-container .content .block-2 
    {
        display: none;
    }

    .home-container .content h2.title.sm-title {
        font-size: min(40px, 10vw);
    }

    .home-container .content .block-4 .block-container h2
    {
        font-size: min(50px, 8vw);
    }

    .home-container .content .block-6 .block-container h2
    {
        font-size: min(60px, 8vw);
    }

    .home-container .content .block-6, .home-container .content .block-4
    {
        max-width: 95%;
    }
    
    .home-container .content .block-6 .block-container p {
        font-size: min(24px, 5.5vw);
    }

    .home-container .content p.description
    {
        max-width: 85%;
    }

    .home-container .content p.description
    {
        font-size: min(22px, 4.5vw);
    }
}

@media only screen and (max-width: 500px)
{
    .home-container .content .final-block h2
    {
        font-size: 8vw;
        width: 95%;
    }
}