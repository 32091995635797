.students-container
{
    width: 100%;
    padding: 1.5rem;
}

.students-container h2.title
{
    text-align: center;
    width: 100%;

}

.students-container .students-content 
{
    width: 90%;
    min-height: 40vh;
    border-radius: 50px;
    background: rgba(120, 120, 120, .5);
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    margin: 2rem auto;
    gap: 1.5rem;
    padding-block: 1rem;
    font-size: 30px;
}

.students-container .students-content.not-empty 
{
    background: transparent;
    border: 2px solid #4481eb;
}

.students-container .students-content .student 
{
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 1rem;
    width: 90%;
    margin: 0 auto;
    padding: .5rem;
}

.students-container .students-content .student .user-icon
{
    font-size: 18px;
    color: #4481eb;

}

.students-container .students-content .student p 
{
    background: linear-gradient(135deg, #4481eb 0%, #04befe 100%);
    font-size: 18px;
    font-weight: 600;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.students-container .user .column .action-container
{
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}



.students-container .user .column .action-container .hover-text p 
{
    color: #fff;
    font-weight: 600;
    text-align: center;
}

.students-container .user .column .action-container .action-button 
{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    position: relative;
    z-index: 10;
}

.students-container .user .column .action-container .action-button .action-icon 
{
    width: 20px;
    height: 20px;
    padding: .5rem;
    border-radius: 50%;
    transition: all 350ms ease-in-out;
    
}

.students-container .user .column .action-container .action-button .action-icon:hover 
{
    background: linear-gradient(135deg, #4481eb 0%, #04befe 100%);
    color: #fff;
}

.students-container .user .column .action-container .hover-text 
{
    position: absolute;
    top: -40px;
    /* left: 50%;
    transform: translateX(-50%); */
    background: rgba(0, 0, 0, .8);
    border-radius: 15px;
    opacity: 0;
    z-index: 11;
    padding: .35rem .75rem;
    transition: all 350ms ease-in-out;
}

.students-container .user .column .action-container:hover .hover-text
{
    opacity: 1;
}

.students-container .user .column .action-container:hover .action-button .action-icon 
{
    /* color: #4481eb; */
}
