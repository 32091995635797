.animation-container 
{
    width: 100%;
    min-height: 100vh;
    max-height: 100vh;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
}

.animation-container h1 
{
    text-align: center;
    background: -webkit-linear-gradient(270deg, #4481eb 0%, #04befe 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    transition: all 500ms ease-in-out;
}