header 
{
    width: 100%;
    height: 4vh; 
    min-height: 60px;
    background: linear-gradient(135deg, #4481eb 0%, #04befe 100%);
    /* background: linear-gradient(135deg, rgba(0, 0, 0, .85) 35%, rgba(99, 128, 138, .8)); */

    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 2rem;
    margin-bottom: 2rem;
    border-radius: 50px;
    gap: 3rem;
}

header .element p 
{
    font-size: 20px;
    color: #fff; 
    font-weight: 600;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: .5rem;
}

header .element p .circle 
{
    width: 20px;
    height: 20px;
    border-radius: 50%;
    padding: 1rem;
    background: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    
}

header .element p .circle span
{
    background: -webkit-linear-gradient(135deg, #4481eb 0%, #04befe 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}


header .element .line 
{
    width: 0%;
    height: 3px;
    background: #fff;
    border-radius: 20px;
    transition: all 350ms ease-in-out;
}

header .element p:hover + .line 
{
    width: 100%;
} 

header .element.center-element 
{
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
}

header .element.center-element p
{
    font-weight: bold;
}

header .element.element-pushed 
{
    margin-left: auto;
}