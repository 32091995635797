/* .categories 
{
    width: 100%;
    display: flex;
    flex: 1;
    justify-content: center;
    align-items: center;
    gap: 1rem;
}

.categories .category 
{
    flex: 1;
    aspect-ratio: calc(16 / 9);
} */

.categories-content .modules.high-tension-modules .high-tension.active
{
    box-shadow: 0 0 15px rgb(59,215,102),
    0 0 15px rgb(3,255,28);
}

.work-in-progress 
{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.module-title-container .title-module
{
    padding: .75rem 0;
    color: #fff;
    text-align: center;
    font-size: 30px;
}

.module-title-container
{
    background-image: linear-gradient(135deg, rgba(0, 0, 0, .85) 35%, rgba(99, 128, 138, .8));
    position: fixed;
    bottom: 10px;
    left: 50%;
    transform: translateX(-50%);
    border-radius: 10px;
    width: 35%;

    backdrop-filter: blur(8px);
    z-index: 1000;
}