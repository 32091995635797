.content 
{
    width: 100%;
    min-height: 100vh;
    padding: 2rem;
}

.add-button 
{
    padding: 1rem 2rem;
    background:  linear-gradient(135deg, #4481eb 0%, #04befe 100%);
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 20px;
    gap: 1rem;
    position: fixed;
    bottom: 10px;
    right: 10px;
    opacity: 0;
    z-index: 10;
    user-select: none;
    transition: all 350ms ease-in-out;
}

.add-button.show 
{
    opacity: 1;
    user-select: initial;
    cursor: pointer;
}

.add-button.left-side.show 
{
    right: initial;
    left: 10px;
}

.add-button p 
{
    color: rgba(255, 255, 255, .9);
    font-weight: 600;
    font-size: 20px;
}

.add-button:hover 
{
    border-radius: 35px;
}

.add-button .add-icon 
{
    color: #fff;
    width: 26px;
    height: 26px;
    padding: .3rem;
    transition: all 350ms ease-in-out;
}

.add-button:hover .add-icon
{
    background: #fff;
    border-radius: 50%;
    color: #4481eb;
}

.name 
{
    display: flex;
    align-items: center;
    justify-content: center;
    gap: .5rem;
    padding-bottom: .5rem;
    padding-left: .75rem;
}

.name .user-icon 
{
    padding: .65rem;
    border: none;
    border-radius: 50%;
    width: 24px;
    height: 24px;
    color: #fff;
    background: linear-gradient(135deg, #4481eb 0%, #04befe 100%);
    padding: .8rem;
}

.name p 
{
    font-size: 24px;
    font-weight: 600;
    background: -webkit-linear-gradient(180deg, #000 35%, #63808a);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}


.line 
{
    height: 3px;
    width: 100%;

    border-radius: 20px;
    background: linear-gradient(135deg, #4481eb 0%, #04befe 100%);
 
}

.categories 
{
    display: flex;
    align-items: center;
    margin-block: 1rem 3rem;
    justify-content: center;
}

.categories .category 
{
    padding-left: 1rem;
    padding-right: 1rem;
    cursor: pointer;
    font-size: 24px;
    transition: all 350ms ease-in-out; 
}





.categories .category.active 
{
    border-bottom: 3px solid #4481eb;
}

.empty-container 
{
    width: 90%;
    min-height: 300px;
    background: rgb(212, 212, 212);
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50px;
    margin: 2rem auto;
}

.empty-container h2 
{
    font-size: 50px;
    text-align: center;
}


.projects-container 
{
    width: 100%;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 3rem;
    padding-bottom: 5rem;
    margin-top: 2rem;
}

.projects-container .project-gradient 
{
    width: 100%;
    padding: .325rem;
    background-image: linear-gradient(75deg, #04befe, #4481eb);
    border-radius: 10px;
    z-index: 1;
    position: relative;
    transition: transform 350ms ease-in-out;
}


.projects-container .project-gradient::before 
{
    position: absolute;
    content: '';
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-image: linear-gradient(75deg, #4481eb, #04befe);
    border-radius: 10px;
    z-index: -1;
    transition: opacity 350ms ease-in-out;
    opacity: 0;
}

/* .projects-container .project */
  
.projects-container .project-gradient:hover::before 
{
    opacity: 1;
}

.projects-container .project-gradient:hover
{
    transform: scale(1.05);
}

.projects-container .project-gradient .project
{

    height: 100%;
    border-radius: 5px;
    padding: .5rem;
    min-height: 150px;
    position: relative;
    cursor: pointer;
    background: #fff;
    /* object-fit: cover; */
    /* border: 5px solid #4481eb; */
   
}

.projects-container .project-gradient .project.debugging,
.projects-container .project-gradient .project.functional,
.projects-container .project-gradient .project.learn,
.projects-container .project-gradient .project.test
{
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    background-size: cover;
    background-position: bottom center;
    aspect-ratio: calc(16 / 8);
    padding: 0;
}

.projects-container .project-gradient .project.test
{
    background-position: top center;
    background-size:100%;
}

.projects-container .project-gradient .project.learn.cell-SRS
{
    background-position: center;
    background-size: cover;
}

.projects-container .project-gradient:first-child .project.functional
{
    background-image: linear-gradient(180deg, rgba(0, 0, 0, .1), rgba(0, 0, 0, 0), rgba(0, 0, 0, .2)), url("../../imgs/functional-1.png");
}

.projects-container .project-gradient:nth-child(2) .project.functional
{
    background-image: linear-gradient(180deg, rgba(0, 0, 0, .1), rgba(0, 0, 0, 0), rgba(0, 0, 0, .2)), url("../../imgs/functional-2.png");
}

.projects-container .project-gradient:nth-child(3) .project.functional
{
    background-image: linear-gradient(180deg, rgba(0, 0, 0, .1), rgba(0, 0, 0, 0), rgba(0, 0, 0, .2)), url("../../imgs/functional-3.png");
}

.projects-container .project-gradient:last-child .project.functional
{
    background-image: linear-gradient(180deg, rgba(0, 0, 0, .1), rgba(0, 0, 0, 0), rgba(0, 0, 0, .2)), url("../../imgs/functional-4.png");
}

.projects-container .project-gradient:first-child .project.debugging
{
    background-image: linear-gradient(180deg, rgba(0, 0, 0, .1), rgba(0, 0, 0, 0), rgba(0, 0, 0, .2)), url("../../imgs/debugging-1.png");
}

.projects-container .project-gradient:nth-child(2) .project.debugging
{
    background-image: linear-gradient(180deg, rgba(0, 0, 0, .1), rgba(0, 0, 0, 0), rgba(0, 0, 0, .2)), url("../../imgs/debugging-2.png");
}

.projects-container .project-gradient:last-child .project.debugging
{
    background-image: linear-gradient(180deg, rgba(0, 0, 0, .1), rgba(0, 0, 0, 0), rgba(0, 0, 0, .2)), url("../../imgs/debugging-3.png");
}

.projects-container .project-gradient:first-child .project.learn
{
    background-image: linear-gradient(180deg, rgba(0, 0, 0, .1), rgba(0, 0, 0, 0), rgba(0, 0, 0, .2)), url("../../imgs/high-tension.png");
}

.projects-container .project-gradient:nth-child(2) .project.learn
{
    background-image: linear-gradient(180deg, rgba(0, 0, 0, .1), rgba(0, 0, 0, 0), rgba(0, 0, 0, .2)), url("../../imgs/high-tension.png");
}


.projects-container .project-gradient:first-child .project.test
{
    background-image: linear-gradient(180deg, rgba(0, 0, 0, .1), rgba(0, 0, 0, 0), rgba(0, 0, 0, .2)), url("../../imgs/electrocutare.webp");
}

.projects-container .project-gradient:nth-child(2) .project.test
{
    background-image: linear-gradient(180deg, rgba(0, 0, 0, .1), rgba(0, 0, 0, 0), rgba(0, 0, 0, .2)), url("../../imgs/fire_scenario.jpeg");
}

.projects-container .project-gradient:first-child .project.learn.cell-SRS
{
    background-image: linear-gradient(180deg, rgba(0, 0, 0, .1), rgba(0, 0, 0, 0), rgba(0, 0, 0, .2)), url("../../imgs/stations/SRS/tenaris-A.png");
}

.projects-container .project-gradient:nth-child(2) .project.learn.cell-SRS
{
    background-image: linear-gradient(180deg, rgba(0, 0, 0, .1), rgba(0, 0, 0, 0), rgba(0, 0, 0, .2)), url("../../imgs/stations/SRS/tenaris-B.png");
}

.projects-container .project-gradient:nth-child(3) .project.learn.cell-SRS
{
    background-image: linear-gradient(180deg, rgba(0, 0, 0, .1), rgba(0, 0, 0, 0), rgba(0, 0, 0, .2)), url("../../imgs/stations/SRS/tenaris-A.png");
}

.projects-container .project-gradient:nth-child(4) .project.learn.cell-SRS
{
    background-image: linear-gradient(180deg, rgba(0, 0, 0, .1), rgba(0, 0, 0, 0), rgba(0, 0, 0, .2)), url("../../imgs/stations/SRS/tenaris-B.png");
}

.projects-container .project-gradient:nth-child(5) .project.learn.cell-SRS
{
    background-image: linear-gradient(180deg, rgba(0, 0, 0, .1), rgba(0, 0, 0, 0), rgba(0, 0, 0, .2)), url("../../imgs/stations/SRS/tenaris-OE1.png");
    background-size: contain;
    background-repeat: no-repeat;
}


.projects-container .project-gradient:nth-child(6) .project.learn.cell-SRS
{
    background-image: linear-gradient(180deg, rgba(0, 0, 0, .1), rgba(0, 0, 0, 0), rgba(0, 0, 0, .2)), url("../../imgs/stations/SRS/tenaris-OE2.png");
    background-size: contain;
    background-repeat: no-repeat;
}

.projects-container .project-gradient:nth-child(7) .project.learn.cell-SRS
{
    background-image: linear-gradient(180deg, rgba(0, 0, 0, .1), rgba(0, 0, 0, 0), rgba(0, 0, 0, .2)), url("../../imgs/stations/SRS/tenaris-OE3.png");
    background-size: contain;
    background-repeat: no-repeat;
}


.projects-container .project-gradient:nth-child(8) .project.learn.cell-SRS
{
    background-image: linear-gradient(180deg, rgba(0, 0, 0, .1), rgba(0, 0, 0, 0), rgba(0, 0, 0, .2)), url("../../imgs/stations/SRS/tenaris-OE4.png");
    background-size: contain;
    background-repeat: no-repeat;
}


/* .projects-container .project-gradient:nth-child(9) .project.learn.cell-SRS
{
    background-image: linear-gradient(180deg, rgba(0, 0, 0, .1), rgba(0, 0, 0, 0), rgba(0, 0, 0, .2)), url("../../imgs/stations/SRS/tenaris-CLI.png");
    background-size: contain;
    background-repeat: no-repeat;
} */

.projects-container .project-gradient:nth-child(9) .project.learn.cell-SRS
{
    background-image: linear-gradient(180deg, rgba(0, 0, 0, .1), rgba(0, 0, 0, 0), rgba(0, 0, 0, .2)), url("../../imgs/stations/SRS/tenaris-GeneratorI.png");
    background-size: contain;
    background-repeat: no-repeat;
}

.projects-container .project-gradient:nth-child(10) .project.learn.cell-SRS
{
    background-image: linear-gradient(180deg, rgba(0, 0, 0, .1), rgba(0, 0, 0, 0), rgba(0, 0, 0, .2)), url("../../imgs/stations/SRS/tenaris-GeneratorII.png");
    background-size: contain;
    background-repeat: no-repeat;
}

.projects-container .project-gradient:nth-child(11) .project.learn.cell-SRS
{
    background-image: linear-gradient(180deg, rgba(0, 0, 0, .1), rgba(0, 0, 0, 0), rgba(0, 0, 0, .2)), url("../../imgs/stations/SRS/tenaris-CMIA.png");
    background-size: contain;
    background-repeat: no-repeat;
}

.projects-container .project-gradient:nth-child(12) .project.learn.cell-SRS
{
    background-image: linear-gradient(180deg, rgba(0, 0, 0, .1), rgba(0, 0, 0, 0), rgba(0, 0, 0, .2)), url("../../imgs/stations/SRS/tenaris-CMIB.png");
    background-size: contain;
    background-repeat: no-repeat;
}


.projects-container .project-gradient:nth-child(13) .project.learn.cell-SRS
{
    background-image: linear-gradient(180deg, rgba(0, 0, 0, .1), rgba(0, 0, 0, 0), rgba(0, 0, 0, .2)), url("../../imgs/stations/SRS/tenaris-CMIIA.png");
    background-size: contain;
    background-repeat: no-repeat;
}

.projects-container .project-gradient:nth-child(14) .project.learn.cell-SRS
{
    background-image: linear-gradient(180deg, rgba(0, 0, 0, .1), rgba(0, 0, 0, 0), rgba(0, 0, 0, .2)), url("../../imgs/stations/SRS/tenaris-CMIIB.png");
    background-size: contain;
    background-repeat: no-repeat;
}

.projects-container .project-gradient:nth-child(15) .project.learn.cell-SRS
{
    background-image: linear-gradient(180deg, rgba(0, 0, 0, .1), rgba(0, 0, 0, 0), rgba(0, 0, 0, .2)), url("../../imgs/stations/SRS/tenaris-LPGS2.png");
    background-size: contain;
    background-repeat: no-repeat;
}


.projects-container .project-gradient:nth-child(16) .project.learn.cell-SRS
{
    background-image: linear-gradient(180deg, rgba(0, 0, 0, .1), rgba(0, 0, 0, 0), rgba(0, 0, 0, .2)), url("../../imgs/stations/SRS/tenaris-2T.png");
    background-size: contain;
    background-repeat: no-repeat;
}

.projects-container .project-gradient:nth-child(17) .project.learn.cell-SRS
{
    background-image: linear-gradient(180deg, rgba(0, 0, 0, .1), rgba(0, 0, 0, 0), rgba(0, 0, 0, .2)), url("../../imgs/stations/SRS/tenaris-3T.png");
    background-size: contain;
    background-repeat: no-repeat;
}


.projects-container .project-gradient .project h2 
{
  
    display: flex;
    /* justify-content: center; */
    align-items: center;
    gap: .5rem;
    text-align: center;
    color: #fff;
    justify-content: center;
    background: linear-gradient(90deg, rgb(0, 0, 0), rgb(80,103,111));  
      
    width: 100%;
    /* align-self: flex-end; */
    /* margin-bottom: -1px; */
    /* border-radius: 10px; */
    /* margin-bottom: 20px; */
    margin: 0 auto;
    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px;

    /* font-size: 24px; */

}

.projects-container .project-gradient .project:not(.debugging):not(.functional):not(.learn):not(.test) h2
{
    justify-content: initial;
    background: -webkit-linear-gradient(135deg, rgb(76, 76, 76) 70%, #5a6a70 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    margin-top: initial;
    width: 90%;
    margin-left: 0;
}



.projects-container .project-gradient .project.functional .format,
.projects-container .project-gradient .project.debugging .format,
.projects-container .project-gradient .project.learn .format,
.projects-container .project-gradient .project.test .format
{
    display: flex;
    align-items: center;
    gap: .5rem;
    position: absolute;
    top: 5px;
    right: 5px;
    font-size: 24px;
    border-radius: 8px;
    /* background: -webkit-linear-gradient(135deg, #4481eb 70%, #000000 100%); */
    /* -webkit-background-clip: text;
    -webkit-text-fill-color: transparent; */
    color: #fff;
    background-image: linear-gradient(-45deg, #000 35%, #63808a);

    padding: .5rem .75rem;
    transition: all 350ms ease-in-out;
    
}

.projects-container .project-gradient .project:not(.functional):not(.debugging):not(.learn):not(.test) .format 
{
    display: flex;
    align-items: center;
    gap: .5rem;
    position: absolute;
    bottom: 5px;
    font-size: 24px;
    background: -webkit-linear-gradient(135deg, #4481eb 70%, #000000 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.projects-container .project-gradient .project .format:hover 
{
    /* padding: .75rem; */
    padding: .5rem .65rem;
    border-radius: 50%;
}

.projects-container .project-gradient .project .format .format-icon 
{
    /* color: #4481eb; */
    color: #4481eb;
}

.projects-container .project-gradient .project.functional .format .format-icon,
.projects-container .project-gradient .project.debugging .format .format-icon,
.projects-container .project-gradient .project.learn .format .format-icon,
.projects-container .project-gradient .project.test .format .format-icon
{
    display: none;
}



.project .delete-icon
{
    position: absolute;
    top: 15px;
    right: 15px;
    color: crimson;
    width: 24px;
    height: 24px;
    z-index: 10;
    transition: all 350ms ease-in-out;
}

.project .delete-icon:hover
{
    top: 7.5px;
    right: 7.5px;
    padding: .75rem;
    background: crimson;
    border-radius: 50%;
   
    color: rgba(255, 255, 255, .9);

}


.project .edit-container 
{
    position: relative;
    bottom: -2px;
    /* right: 5px; */
}

.project .edit-container .edit-icon 
{
    color: #4481eb;
    width: 24px;
    height: 24px;
    padding: .5rem;
    transition: all 350ms ease-in-out;  
}


.project .edit-container .edit-icon:hover 
{
    background: #4481eb;
    border-radius: 50%;
    padding: .75rem;
    color: rgba(255, 255, 255, .8);
}


.switch-options 
{
    display: flex;
    justify-content: center;
    align-items: center;
    /* gap: 1rem; */
    border: 2px solid #4481eb;
    width: fit-content;
 
    margin: 0 auto 3rem;
    border-radius: 30px;
}

.switch-options .switch-option
{
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    width: 100%;
    padding: 1rem 3rem;
    transition: all 350ms ease-in-out;
}

.switch-options .switch-option:first-child
{
    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;
}   

.switch-options .switch-option:last-child
{
    border-top-right-radius: 20px;
    border-bottom-right-radius: 20px;
}

.switch-options .switch-option.active
{
    background: #4481eb;
}

.switch-options .switch-option:not(:last-child)
{
    border-right: 3px solid #000;
}

.switch-options .switch-option p 
{
    transition: all 350ms ease-in-out;
    font-size: 20px;
    font-weight: 600;
    white-space: nowrap;
}

.switch-options .switch-option.active p 
{
    color: #fff;
}

.project-content .title 
{
    text-align: center;
    margin: 2rem;
    font-size: 45px;
}

.project-content .go-back
{
    width: 60px;
    height: 60px;
    border-radius: 50%;
    background: linear-gradient(90deg, rgb(0, 0, 0), rgb(80,103,111));
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    position: absolute;
    top: calc(4vh + 100px);
    z-index: 1000;
    transition: all 350ms ease-in-out;
}

.project-content .go-back .back-icon 
{
    font-size: 30px;
    color: #fff;
}

.project-content .go-back:hover
{
    width: 75px;
    height: 75px;
}