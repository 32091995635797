.problem-button 
{
    opacity: 0;
    
    position: fixed;
    top: 20px;
    right: 20px;
    background: crimson;
    padding: 1rem;
    border-radius: 50px;
    color: #fff;
    z-index: 100000;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: .5rem;
    transition: all 350ms ease-in-out;
}

.screw-button 
{    
    
    opacity: 0;
    position: fixed;
    top: 25px;
    left: 100px;
    background: linear-gradient(135deg, #4481eb 0%, #04befe 100%);
    padding: 1rem;
    border-radius: 50px;
    color: #fff;
    z-index: 100000;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: .5rem;
    transition: all 350ms ease-in-out;
}

.screw-button.show 
{
    opacity: 1;
    cursor: pointer;
}

.problem-button.solve
{
    background: rgb(21, 160, 21);
}

.problem-button.show 
{
    opacity: 1;
    cursor: pointer;
}

.problem-button.show:hover 
{
    transform: scale(1.05);
    box-shadow: 0 0 10px crimson;
}

.screw-button:hover 
{
    transform: scale(1.05);
    box-shadow: 0 0 10px #4481eb;
}

.problem-button.solve.show:hover 
{
    box-shadow: 0 0 10px rgb(21, 160, 21);
}

.add-conductor 
{
    opacity: 0;
    
    position: fixed;
    top: 20px;
    left: 100px;
    background: linear-gradient(135deg, #4481eb 0%, #04befe 100%);
    padding: 1rem;
    border-radius: 50px;
    color: #fff;
    z-index: 1000;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: .5rem;
    pointer-events: none;
    transition: all 350ms ease-in-out;
}

.add-conductor.show 
{
    pointer-events: all;
    opacity: 1;
    cursor: pointer;
}

.add-conductor.show:hover 
{
    box-shadow: 0 0 10px #4481eb,
                0 0 10px #04befe;
}

.start-session-button 
{
    width: 40%;
    position: fixed;
    bottom: 10px;
    background: linear-gradient(135deg, #4481eb 0%, #04befe 100%);
    left: 50%;
    transform: translateX(-50%);
    padding: .75rem;
    border-radius: 15px;
    z-index: 10000000000;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: .5rem;
    transition: all 350ms ease-in-out;
}

.start-session-button .button-icon 
{
    color: #fff;
    cursor: pointer;
    width: 26px;
    height: 26px;
    border-radius: 50%;
    padding: .5rem;
    transition: all 350ms ease-in-out;
}

.start-session-button:hover 
{
    padding: 1.25rem .75rem;
    width: 55%;
    border-radius: 50px;
}

.start-session-button:hover .button-icon 
{
    width: 30px;
    height: 30px;
}

.start-session-button p 
{
    text-align: center;
    color: #fff;
    font-weight: bold;
    cursor: pointer;
    font-size: 22px;
    transition: all 350ms ease-in-out;
}

.start-session-button:hover p
{
    font-size: 25px;
}

.defects-options-container
{
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, .3);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000000000;
}

.defects-options-container .options-container 
{
    width: 35vw;
    background: #fff;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    padding: 1rem;
    padding-bottom: 0;
    border-radius: 25px;
    position: fixed;
    aspect-ratio: calc(16 / 9);
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}

.defects-options-container .options-container .save-button 
{
    display: flex;
    margin: 1rem auto;
    width: 50%;
    padding: 1rem;
    border-radius: 50px;
    justify-content: center;
    background: rgb(186, 185, 185);
    cursor: pointer;
    transition: all 350ms ease-in-out;
}

.defects-options-container .options-container .save-button.clicked
{
    background: linear-gradient(135deg, #4481eb 0%, #04befe 100%);
}

.defects-options-container .options-container .save-button p 
{
    text-align: center;
    color: #fff;
}

.defects-options-container .options-container .close-icon
{
    color: crimson;
    position: absolute;
    top: 15px;
    right: 15px;
    width: 22px;
    height: 22px;
    cursor: pointer;
    transition: all 350ms ease-in-out;
}

.defects-options-container .options-container .close-icon:hover
{
    background: crimson;
    border-radius: 50%;
    color: #fff;
    padding: .5rem;
    right: 14px;
    top: 7px;
}


.defects-options-container .options-container h2 
{
    color: #000;
    font-size: 22px;
    text-align: center;
}

.defects-options-container .options-container .option 
{
    background: rgba(125, 125, 125, .12);
    padding: .5rem;
    border-radius: 50px;
    display: flex;
    gap: .5rem;
    cursor: pointer;
    transition: all 350ms ease-in-out;
}

.defects-options-container .options-container .option .circle 
{
    border: 1px solid #000;
    width: 25px;
    height: 25px;
    border-radius: 50%;
    transition: all 350ms ease-in-out;
}

.defects-options-container .options-container .option.active
{
    background: linear-gradient(135deg, #4481eb 0%, #04befe 100%);
    color: #fff;
}

.defects-options-container .options-container .option.active .circle 
{
    border: 1px solid #fff;
    display: flex;
    justify-content: center;
    align-items: center;
}

.defects-options-container .options-container .option .circle .circle-icon 
{
    opacity: 0;
    color: #fff;
    font-size: 18px;
    transition: all 350ms ease-in-out;
}

.defects-options-container .options-container .option.active .circle .circle-icon 
{
    opacity: 1;
}


